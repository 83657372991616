import React from "react";
import { Helmet } from "react-helmet";

const MetaHelmet = ({ title }) => {
  return (
    <Helmet>
      <title>{title} - CreativeGreg</title>
      <meta
        name="description"
        content="Párfotózás, Kismama, Újszülött és Baba fotózás Győrben és környékén. Reklámfotók készítése honlapokhoz vagy közösségi médiára"
      />
      <meta name="keywords" content="Kismama, Újszülött, Baba fotózás, Párfotózás" />
    </Helmet>
  );
};

export default MetaHelmet;
