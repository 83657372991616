import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import logo from "../../assets/logo.svg";

const Footer = () => {
  return (
    <div className="creativegreg_footer">
      <div className="creativegreg_footer-links">
        <div className="creativegreg_footer-links_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="creativegreg_footer-links_div">
          <h4>Oldalak</h4>
          <Link to="/portfolio">Portfólió</Link>
          <Link to="/rolam">Rólam</Link>
        </div>
        <div className="creativegreg_footer-links_div">
          <h4>Információk</h4>
          <Link to="/">Sütik (cookie) használata</Link>
          <Link to="/links">Social Media</Link>
        </div>
        <div className="creativegreg_footer-links_div">
          <h4>Elérhetőség</h4>
          <p>Győr, Magyarország</p>
          <p>greg@creativegreg.hu</p>
        </div>
      </div>
      <div className="creativegreg_footer-copyright">
        <p>Minden jog fenntartva © 2022 Nagy Gergő. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
