import React from "react";
import "./aboutPage.css";
import Avatar from "../../assets/avatar.jpg";
import { Footer } from "../../containers";
import { Card, MetaHelmet } from "../../components";

const AboutPage = () => {
  return (
    <div className="creativegreg_about">
      <MetaHelmet title="Rólam" />
      <div className="creativegreg_about-body">
        <div className="creativegreg_about-body-column">
          <h1 className="creativegreg_about-body-column-header">Kedves Látogató</h1>
          <h3 className="creativegreg_about-body-column-alt">Nagy Gergő vagyok, fényképész</h3>
          <p className="creativegreg_about-body-column-text">
            Első digitális fényképezőgépemet tizenévesen vettem, azóta is szerelmese vagyok a
            fotózásnak. Korábban Európát járva fotóztam rengeteget, míg meg nem alapítottam a
            CreativeGreg-et. Jelenleg Győrben élek feleségemmel és kislányommal. Itt fotózással és
            mellette weboldalak készítésével foglalkozom. Mindkét szakma kreatív látásmódot igényel,
            ezért is állnak közel hozzám. Az ügyfelek elégedettsége a legfontosabb számomra, a
            fotóimat maximális odafigyeléssel készítem.
          </p>
        </div>
        <div className="creativegreg_about-column">
          <Card imgUrl={Avatar} clickFunction="false" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
