import React from "react";
import './contactPage.css'
import Contact from "../../containers/contact/Contact";
import { Footer } from "../../containers";
import { MetaHelmet } from "../../components";

const ContactPage = () => {
  return (
    <div className="creativegreg_contact-page">
      <MetaHelmet title="Elérhetőságek" />
      <Contact />
      <Footer />
    </div>
  );
};

export default ContactPage;
