import React from "react";
import "./portfolio.css";
import { Card } from "../../components";
import Masonry from "react-masonry-css";

import { tumbnails } from "./imports";

const breakpointColumns = {
  default: 3,
  800: 1,
};

const motionVariant = {
  initial: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { duration: 1 },
  },
};

const motionVariant2 = {
  initial: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { duration: 1.5 },
  },
};
const motionVariant3 = {
  initial: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { duration: 1 },
  },
};

const Portfolio = () => {
  return (
    <div className="creativegreg_portfolio" id="portfolio">
      <Masonry
        breakpointCols={breakpointColumns}
        className="creativegreg_portfolio-cards"
        columnClassName="creativegreg_portfolio-cards_column"
      >
        <Card
          imgUrl={tumbnails.portre}
          title="Portré és Párfotózás"
          route="/portfolio/portre"
          selectedCategory="portre"
          motionVariant={motionVariant}
        />
        <Card
          imgUrl={tumbnails.pocak}
          title="Pocakfotózás"
          route="/portfolio/pocak"
          selectedCategory="pocak"
          motionVariant={motionVariant2}
        />
        <Card
          imgUrl={tumbnails.baba}
          title="Babafotózás"
          route="/portfolio/ujszulott"
          selectedCategory="newborn"
          motionVariant={motionVariant3}
        />
      </Masonry>
    </div>
  );
};

export default Portfolio;
