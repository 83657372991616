// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig =  {
//     apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
//     databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
//     projectId:  process.env.REACT_APP_FIREBASE_PROJECTID,
//     storageBucket: process.env.REACT_APP_FIREBASE_SBUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MSID,
//     appId: process.env.REACT_APP_FIREBASE_APPID,
//   };

  const firebaseConfig = {
    apiKey: "AIzaSyAFckVxPP2yvdWfqdEyBaF9mjh9zT_V_g8",
    authDomain: "creativegreg-web.firebaseapp.com",
    databaseURL: "https://creativegreg-web-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "creativegreg-web",
    storageBucket: "creativegreg-web.appspot.com",
    messagingSenderId: "597385930469",
    appId: "1:597385930469:web:2b0e57530421407f6a160c",
  };
 
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFireStore = firebase.firestore();
const dbFireStore = getFirestore();

const timestamp = firebase.firestore.FieldValue.serverTimestamp();

export { projectStorage, projectFireStore, timestamp, dbFireStore };
