import React from "react";
import "./WelcomePage.css";
import { ParallaxBanner } from "react-scroll-parallax";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Masonry from "react-masonry-css";

import welcome_img from "../../assets/newborn/DSCF0906-Edit.jpeg";
import logo from "../../assets/logo.svg";
import { Footer } from "../../containers";
import { Card, MetaHelmet, SocialMedia } from "../../components";

import kapcsolat from "../../assets/headfullimg.jpeg";
import avatar from "../../assets/avatar.jpg";
import portfolio from "../../assets/portfolio.jpg";

const WelcomePage = () => {
  const navigate = useNavigate();

  const breakpointColumns = {
    default: 3,
    800: 1,
  };

  const motionVariant = {
    initial: { opacity: 0 },
    show: {
      opacity: 1,
      transition: { duration: 1 },
    },
  };
  return (
    <div className="welcome_page">
      <MetaHelmet title="Portfólió" />
      <ParallaxBanner
        layers={[{ image: `${welcome_img}`, speed: -30 }]}
        className="welcome_page_parallax"
      >
        <div className="welcome_page_insideStyle" onClick={() => navigate("/portfolio")}>
          <motion.img
            className="welcome_page_logo"
            src={logo}
            alt="creativegreg"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 2 }}
          />

          <motion.div
            className="container"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 4 }}
          >
            <div className="chevron"></div>
            <div className="chevron"></div>
            <div className="chevron"></div>
          </motion.div>
        </div>
      </ParallaxBanner>
      <div className="creativegreg_links-body">
        <h1 className="creativegreg_links-body-header">Nagy Gergő</h1>
        <h3 className="creativegreg_links-body-alt">Fotográfus és webdesigner</h3>
        <Masonry
          breakpointCols={breakpointColumns}
          className="creativegreg_portfolio-cards"
          columnClassName="creativegreg_portfolio-cards_column"
        >
          <Card
            imgUrl={portfolio}
            title="Portfólió ->"
            route="/portfolio"
            selectedCategory="all"
            motionVariant={motionVariant}
          />
          <Card
            imgUrl={kapcsolat}
            title="Kapcsolat ->"
            route="/Kapcsolat"
            motionVariant={motionVariant}
          />
          <Card
            imgUrl={avatar}
            title="Rólam ->"
            route="/rolam"
            motionVariant={motionVariant}
          />
        </Masonry>
      </div>
      <SocialMedia />
      <Footer />
    </div>
  );
};

export default WelcomePage;
