import Masonry from "react-masonry-css";
import React from "react";
import "./imageGrid.css";
// import { imgData } from "./imports";

const breakpointColumnsObj = {
  default: 4,
  1500: 3,
  1000: 2,
  700: 1,
};

const ImageGrid = ({ setSelecteImg, selectedCategoryImgs }) => {
  let Scroll = require("react-scroll");
  let Element = Scroll.Element;

  return (
    <Element name="inFocus" id="inFocus">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="creativegreg_imggrid"
        columnClassName="creativegreg_imggrid_column"
      >
        {selectedCategoryImgs &&
          selectedCategoryImgs.map((img) => (
            <img src={img.url} alt={img.category} key={img.id} onClick={() => setSelecteImg(img)} />
          ))}
      </Masonry>
    </Element>
  );
};

export default ImageGrid;
