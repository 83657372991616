import React from "react";
import { Link } from "react-router-dom";
import "./linksPage.css";
import headImg from "../../assets/headImg.png";
import { Footer } from "../../containers";
import { MetaHelmet, SocialMedia } from "../../components";

const LinksPage = () => {
  return (
    <div className="creativegreg_links">
      <MetaHelmet title="Social Media Linkek" />
      <div className="creativegreg_links-body">
        <div className="creative-link-head">
          <img src={headImg} alt="head img" />
        </div>

        <h1 className="creativegreg_links-body-header">CreativeGreg</h1>
        <h3 className="creativegreg_links-body-alt">Fotográfus és webdesigner</h3>
        <div className="creativegreg_links-body-cards">
          <div className="creativegreg_links-body-cardelement">
            <Link to="/">CreativeGreg Fotó</Link>
          </div>
          <div className="creativegreg_links-body-cardelement">
            <a href="https://creativegreg.myportfolio.com/" target={"_blank"} rel="noreferrer">
              Fotó Portfólió
            </a>
          </div>
          <div className="creativegreg_links-body-cardelement">
            <a href="https://gregdesign.hu/" target={"_blank"} rel="noreferrer">
              Weboldal készítés
            </a>
          </div>
        </div>
      </div>
      <SocialMedia />
      <Footer />
    </div>
  );
};

export default LinksPage;
