import React, { useState } from "react";
import "./contact.css";
import emailjs from "@emailjs/browser";
import { Modal, Button } from "react-bootstrap";

import { useForm } from "react-hook-form";

export default function Contact() {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [message, setMessage] = useState("");

  const onSubmit = (data, e) => {

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_HOST,
        process.env.REACT_APP_EMAILJS_TEMPLATE,
        data,
        process.env.REACT_APP_EMAILJS_PUBLICKEY
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );

    //reset field after sending message
    setMessage(data.name);
    reset();
  };

  // console.log("Error in form: ", errors);

  return (
    <div className="creativegreg_contact" id="contact">
      <h1>Kapcsolat</h1>

      {message}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="creativegreg_contact-form">
          <div className="creativegreg_contact-form-column">
            <input
              type="text"
              placeholder="Név*"
              {...register("name", { required: true, minLength: 5, maxLength: 80 })}
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
            />
            {/* {errors.Name && <span>This field is required</span>} */}
            <input
              type="text"
              placeholder="Email*"
              {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
            />
            <input
              type="tel"
              placeholder="Telefonszám"
              {...register("phone", { required: false, minLength: 6, maxLength: 12 })}
            />
          </div>
          <div className="creativegreg_contact-form-column">
            <textarea
              {...register("message", { required: true, min: 10 })}
              className={`form-control ${errors.message ? "is-invalid" : ""}`}
            />
          </div>
        </div>
        <div className="creativegreg_contact-form-bottom">
          <p className="creativegreg_contact-cookies">
            Az adatok nem kerülnek megőrzésre, csak a kapcsolatfelvételhez használatosak és
            garantáljuk, hogy harmadik félnek sem kerül soha átadásra!
          </p>
          <button type="submit" onClick={handleSubmit(onSubmit)}>
            Küldés
          </button>
        </div>
      </form>

      <Modal keyboard={false} id="creativegreg_contact-form-response" show={message !== ""} centered>
        <Modal.Body className="creativegreg_contact-form-response-body">
          <p>Köszönöm a megkeresésed {message}. Rövidesen válaszolok.</p>
        </Modal.Body>

        <Modal.Footer className="creativegreg_contact-form-response-footer">
          <Button variant="secondary" onClick={() => setMessage("")}>
            Bezár
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
