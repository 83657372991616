import React from "react";
import { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import "./navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="navbar_wrap">
      <div className="creativegreg__navbar">
        <div className="creativegreg__navbar-links">
          <div className="creativegreg__navbar-links_container">
            <p>
              <Link to="/portfolio">Portfólió</Link>
            </p>
            <p>
              <Link to="/rolam">Rólam</Link>
            </p>
          </div>
        </div>

        <div className="creativegreg__navbar-links_logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>

        <div className="creativegreg__navbar-right">
          <div className="creativegreg__navbar-contact">
            <Link to="/kapcsolat" type="button">
              Kapcsolat
            </Link>
          </div>

          <div className="creativegreg__navbar-menu">
            {toggleMenu ? (
              <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
            ) : (
              <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
            )}
            {toggleMenu && (
              <div className="creativegreg__navbar-menu_container scale-up-center">
                <div className="creativegreg__navbar-menu_container-links">
                  <div>
                    <p>
                      <Link to="/" onClick={() => setToggleMenu(false)}>
                        Főoldal
                      </Link>
                    </p>
                    <p>
                      <Link to="/portfolio" onClick={() => setToggleMenu(false)}>
                        Portfólió
                      </Link>
                    </p>
                    <p>
                      <Link to="/rolam" onClick={() => setToggleMenu(false)}>
                        Rólam
                      </Link>
                    </p>
                  </div>
                  <div className="creativegreg__navbar-menu_container-contact">
                    <Link to="/kapcsolat" type="button" onClick={() => setToggleMenu(false)}>
                      Kapcsolat
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
