import { useState, useEffect } from "react";
import { projectFireStore } from "../firebase/config";

const useFirestore = (collection, category) => {
  const [imgs, setImgs] = useState([]);

  useEffect(() => {
    if (category !== "all") {
      const unsub = projectFireStore
        .collection(collection)
        .where("category", "==", category)
        .orderBy("createdAt", "desc")
        .onSnapshot((snpa) => {
          let documents = [];
          snpa.forEach((doc) => {
            documents.push({ ...doc.data(), id: doc.id });
          });
          setImgs(documents);
        });

      return () => unsub();
    } else {
      const unsub = projectFireStore
        .collection(collection)
        .orderBy("createdAt", "desc")
        .onSnapshot((snpa) => {
          let documents = [];
          snpa.forEach((doc) => {
            documents.push({ ...doc.data(), id: doc.id });
          });
          setImgs(documents);
        });

      return () => unsub();
    }
  }, [collection, category]);

  return { imgs };
};

export default useFirestore;
