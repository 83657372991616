import React from "react";
import "./modal.css";
import { motion } from "framer-motion";

const Modal = ({ selectedImg, setSelecteImg }) => {
  const handleClick = (e) => {
    setSelecteImg(null);
    // if (e.target.classList.contains("creativegreg_modal-backdrop")) {/*Only close model if clicked on backdrop */    }
  };

  return (
    <motion.div
      className="creativegreg_modal-backdrop"
      onClick={handleClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <motion.img src={selectedImg.url} alt="Large img" initial={{ y: "-100vh" }} animate={{ y: 0 }} />
    </motion.div>
  );
};

export default Modal;
