import React from "react";
import "./socialMedia.css";

const SocialMedia = () => {
  return (
    <div className="cg-icons-wrapper">
      <div className="icons">
        <a
          href="https://www.instagram.com/creativegregphoto/"
          target={"_blank"}
          rel="noreferrer"
          className="icon icon--instagram"
        >
          <i className="ri-instagram-line"></i>
        </a>
        <a
          href="https://twitter.com/TheCreativeGreg"
          target={"_blank"}
          rel="noreferrer"
          className="icon icon--twitter"
        >
          <i className="ri-twitter-line"></i>
        </a>
        <a
          href="https://www.linkedin.com/in/gergo-nagy/"
          target={"_blank"}
          rel="noreferrer"
          className="icon icon--linkedin"
        >
          <i className="ri-linkedin-line"></i>
        </a>
        <a
          href="https://github.com/GergoNagy"
          target={"_blank"}
          rel="noreferrer"
          className="icon icon--github"
        >
          <i className="ri-github-line"></i>
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
