import React, { useEffect } from "react";
import "./App.css";
import { Navbar } from "./components";
import { Routes, Route, useLocation } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import GA4React from "ga-4-react";
import { AboutPage, ContactPage, LinksPage, PortfolioPage, WelcomePage } from "./pages";

const App = () => {
  window.addEventListener("contextmenu", (e) => {
    console.log("No right click");
    e.preventDefault();
  });

  useEffect(() => {
    const ga4react = new GA4React("G-D23T34T5W3");

    ga4react.initialize().then(
      (ga4) => {
        ga4.pageview("path");
        ga4.gtag("event", "pageview", window.location.pathname + window.location.search);
      },
      (err) => {
        console.error(err);
      }
    );
  }, []);

  const location = useLocation();
  return (
    <ParallaxProvider>
      <div className="App">
        <div className="creativegreg_navbar-gradient_bg">
          {location.pathname !== "/" ? <Navbar /> : null}
        </div>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route>
            <Route path="/kapcsolat" element={<ContactPage />} />
            <Route path="/links" element={<LinksPage />} />
            <Route path="/rolam" element={<AboutPage />} />

            <Route path="/portfolio/" element={<PortfolioPage />} />
            <Route path="/portfolio/:category" element={<PortfolioPage />} />
            <Route path="*" element={<PortfolioPage />} />
          </Route>
        </Routes>
      </div>
    </ParallaxProvider>
  );
};

export default App;
