import React, { useState } from "react";
import "./PortfolioPage.css";
import { useLocation } from "react-router-dom";
import { ImageGrid, MetaHelmet } from "../../components";
import Modal from "../../components/modal/Modal";
import { Footer, Portfolio } from "../../containers";

import useFirestore from "../../hooks/useFirestore";

const PortfolioPage = (props) => {
  const { state } = useLocation();
  const [selectedImg, setSelecteImg] = useState(null);
  const selectedCategory = state ? state.selectedCategory : "all";

  const { imgs } = useFirestore("images", selectedCategory);

  return (
    <div className="creativegreg_portfolioPage">
      <MetaHelmet title="Portfólió" />
      <Portfolio />

      <div className="creativegreg_portfolioPage_body">
        <div className="creativegreg_recent-grid">
          <ImageGrid
            selectedCategory={state ? state.selectedCategory : "all"}
            setSelecteImg={setSelecteImg}
            selectedCategoryImgs={imgs}
          />
        </div>
        {selectedImg && <Modal selectedImg={selectedImg} setSelecteImg={setSelecteImg} />}
      </div>
      <Footer />
    </div>
  );
};

export default PortfolioPage;
