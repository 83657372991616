import React from "react";
import "./card.css";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Scroll from "react-scroll";

const Card = ({ imgUrl, title, route, selectedCategory, motionVariant, clickFunction }) => {
  const navigate = useNavigate();

  return (
    <motion.div
      variants={motionVariant}
      initial="initial"
      whileInView="show"
      // viewport={ { once: true }}
      className="creativegreg_card"
      onClick={() => {
        if (clickFunction !== "false" ) {
          Scroll.scroller.scrollTo("inFocus", {
            duration: 500,
            offset: -15,
            smooth: "easeInOutQuart",
          });

          navigate(route, { state: { selectedCategory } }, { replace: true });
        }
      }}
    >
      <img src={imgUrl} alt="categpry" className="creativegreg_card-img" />
      <h1 className="creativegreg_card-title">{title}</h1>
    </motion.div>
  );
};

export default Card;
